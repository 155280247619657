import axios from 'axios'
export default{
   
    getvideolist:()=>axios.get('/api/fame/OneToOne'),//获取名人堂一对一目录
    getvideo:data=>axios.get('/api/fame/list',{params:data}),//获取名人堂视频列表
    getvideoMenu:id=>axios.get('/api/fame/catalogParam',{params:{courseId:id}}),//获取名人堂视频目录
    getvideodetails:id=>axios.get('/api/fame/details',{params:{courseId:id}}),//获取名人堂视频详情
    getcomment:data=>axios.get('/api/fame/commentParam',{params:data}),//获取名人堂评论列表
    getnoCourse:()=>axios.get('/api/user/isNotSaveCourse'),//获取未发布课程列表
    addCourse:data=>axios.post('/api/fame/release/course',data),//保存课程
    addSection:data=>axios.get('/api/fame/add/chapter',{params:data}),//新增章节
    addSubordinate:data=>axios.post('/api/fame/release/catalog',data),//新增小节
    delrowData:id=>axios.get('/api/fame/catalog/delete',{params:{catalogId:id}}),//删除章节或小节

}