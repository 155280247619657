import Vue from 'vue'
import Vuex from 'vuex'
import avater from '@/assets/avater1.png'
import api from '@/api/api'
import { Message } from 'element-ui'
Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        menuData:[],
        menutotal:0,
        playobj:{},
    },
    mutations: {
        setMenuList(state,data){
            console.log(data);
            state.menuData = data.commentList
            state.menutotal = data.catalogCount
        },
        setplayobj(state,data){
            console.log('item',data);
            state.menuData.forEach(item=>{
                item.isplay = false
                if(item.catalogId==data.catalogId){
                    item.isplay = true
                }
            })
            state.playobj = data
        }
    },
    actions: {
        async getVideoMenuList(cxt,id){
            console.log('id---------------',id);
            const data = await api.mingrentang.getvideoMenu(id)
            let inst = 1
            data.commentList.forEach(item => {
                if(item.catalogLevel=='2'){
                    if(inst==1){
                        cxt.commit('setplayobj',item)
                        item.isplay = true
                    }else{
                        item.isplay = false
                    }
                    item.index = inst
                    inst++
                }
            });
            cxt.commit('setMenuList',data)
        }
    }
}
