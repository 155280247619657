import axios from 'axios'
export default{
    userlogin:data=>axios.post('/api/user/login',data),//用户登录
    userregister:data=>axios.post('/api/user/register',data),//用户注册
    getcode:(phone,key)=>axios.get('/api/user/getCode',{params:{phone,key}}),//获取验证码
    forgetPwd:data=>axios.post('/api/user/forgetPassword',data),//忘记密码
    getloginuserinfo:data=>axios.get('/api/user/personalCenter',{params:data?data:{}}),//获取登录用户信息
    gethotpostList:()=>axios.get('/api/foreign/card/hot'),//获取热门帖子列表
    getCelebrity:()=>axios.get('/api/foreign/celebrity/rank'),//获取名人排行榜
    getbannarimglist:data=>axios.get('/api/foreign/advert',{params:data}),//获取bannar图
    getIdUserInfo:id=>axios.get('/api/user/getUserId',{params:{userId:id}}),//通过用户id获取用户信息
    getPayorder:data=>axios.post('/api/order/preCommit',data),//创建订单
    payorder:data=>axios.post('/api/order/pay',data),//支付订单

    orderPay:data=>axios.post('/api/order/rePay',data),//订单重新支付

    getSHoucangList:data=>axios.get('/api/user/myCollect',{params:data}),//获取收藏列表
    getGuanzhuList:data=>axios.get('/api/user/myInterest',{params:data}),//获取关注列表
    getFensiList:data=>axios.get('/api/user/myFans',{params:data}),//获取粉丝列表

    quxiaoguanzhu:id=>axios.get('/api/user/care',{params:{userId:id}})
}