import Vue from 'vue'
import Vuex from 'vuex'
import avater from '@/assets/avater1.png'
import api from '@/api/api'
import { Message } from 'element-ui'
Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        cartNmae:''
    },
    mutations: {
        setcartName(state,data){
            
            state.cartNmae = data
        },
        
    },
    actions: {
        
    }
}
