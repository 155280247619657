import axios from 'axios'
export default{
    opensBtnapi:data=>axios.post('/api/user/operation',data),
    getchaungzu:data=>axios.get('/api/user/createCenter',{params:data}),//获取创作中心
    getmystudy:data=>axios.get('/api/user/myStudy',{params:data}),//获取我的学习
    getintegral:()=>axios.get('/api/user/integralRecord'),//获取积分明细
    getOrderList:data=>axios.get('/api/user/myOrders',{params:data}),//获取订单列表
    getVideoList:data=>axios.get('/api/user/myCourse',{params:data}),//获取我的课程列表
    getSetuserinfo:()=>axios.get('/api/user/userInfo'),//获取修改的用户信息
    userinfoUpd:data=>axios.post('/api/user/edit',data),//编辑用户信息
    huanbang:data=>axios.get('/api/user/changeBind',{params:data}),//换绑
    xiugaipwd:data=>axios.post('/api/user/changePassword',data),//修改密码
    getmessageList:type=>axios.get('/api/message/list',{params:{messageType:type}}),//获取消息列表
    getuserisqiandao:()=>axios.get('/api/user/isSignAndCoupon'),//获取考勤内容
    qiandao:()=>axios.get('/api/user/sign'),//签到
    careorUcare:id=>axios.get('/api/user/care',{params:{userId:id}}),//取关关注
    getMywendaList:data=>axios.get('/api/user/myAnswer',{params:data}),//获取我的问答
    getyouhuiquan:status=>axios.get('/api/coupon/list',{params:{status}}),//获取优惠券列表
    getMyCollect:type=>axios.get('api/user/myCollect',{params:{collectType:type}}),//获取我的收藏列表
    youhuiOpenObj:data=>axios.get('/api/coupon/apply',{params:data}),//优惠券申请
    erjicomment:data=>axios.post('/api/user/operation/two',data),//二级回复
    transmit:data=>axios.post('/api/foreign/forward',data),//转发

    removeOrder:id=>axios.get('/api/order/remove',{params:{orderId:id}}),//删除订单


    studyopen:id=>axios.get('/api/user/upAndDown',{params:{courseId:id}}),//课程上下架
    studydel:id=>axios.get('/api/user/removeCourse',{params:{courseId:id}}),//课程删除

    geturlimg:data=>axios.get('/api/user/getForwardQRCode',{params:data}),


    deleteWenda:id=>axios.get('/api/answer/removeAnswer',{params:{answerId:id}}),//删除问答
    deleteCard:id=>axios.get('/api/foreign/removeCard',{params:{cardId:id}})//删除帖子

}