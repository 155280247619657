import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'element-ui/lib/theme-chalk/index.css';
import api from '@/api/api'
import '@/api/interceptor'

import vPlayBack from 'v-playback';
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
Vue.use(VueDPlayer);
Vue.use(vPlayBack);
Vue.use(VueVirtualScroller)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$http = api
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
