import Vue from 'vue'
import Vuex from 'vuex'
import homePage from './model/home/homePage'
import videoPage from './model/mingrentang/index'
import waimao from './model/waimao/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    homePage,videoPage,waimao
  }
})
