<template>
  <div id="app">
    <router-view></router-view>
    <!-- <RecycleScroller class="scroller" :items="items" direction="horizontal" :item-size="50" v-if="items.length">
      <template v-slot="{ item }">
        <div class="user">
          {{ item.title }}--- <h4>{{ item.id }}</h4>
        </div>
      </template>
    </RecycleScroller> -->
    <!-- <el-button type="primary" @click="dialogVisible = true">主要按钮</el-button> -->
    <!-- <keep-alive>
        <router-view v-if="$route.meta.isKeepAlive"></router-view>
    </keep-alive> -->
    <!-- <router-view v-if="!$route.meta.isKeepAlive"></router-view> -->

    <!-- <el-dialog custom-class="userOperateBox" width="500px" :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="left"></div>
      <div class="delicon">
        <i class="el-icon-error" @click="dialogVisible=false"></i>
      </div>
    </el-dialog> -->

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      items: []
    }
  },
  created() {
    // this.userloginstatus()
  },
  components: {
    
  },
  methods: {
    userloginstatus(){
      localStorage.getItem('LOGINSTATUS')
    },
    
    
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  user-select: none;
  font-family: "Microsoft YaHei", sans-serif;
}

// ::-webkit-scrollbar {
//   // display: none;
//   width: 0;
//   /* 设置滚动条宽度 */
// }

.scroller {
  // display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 300px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);

  .user {
    height: 50px;
    padding: 0 12px;
    display: flex;
    align-items: center;
  }
}

#app {
  font-family: "Microsoft YaHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  background-color: #F4F6F9;
}

.userOperateBox {
  background-color: rgba($color: #000000, $alpha: 0);

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    background-color: rgba($color: #000000, $alpha: 0);
    position: relative;
    .delicon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 30px;
      cursor: pointer;
      
    }

    .left {
      width: 100%;
      border-radius: 10px;
      background-color: #FFFFFF;
    }
  }

}
</style>
