import Vue from 'vue'
import Vuex from 'vuex'
import avater from '@/assets/avater1.png'
import api from '@/api/api'
import { Message } from 'element-ui'
Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        luyouactive:'100',
        loginStatus:false,//是否登录状态
        loginuserinfo:{},//登录用户信息
        userinfo:{},//项目右侧用户信息
        rightismy:true,//右侧信息模块是否展示自己信息
        isvideolist:false,//是否显示项目右侧视频列表
        isUserinfo:true,//是否显示项目右侧用户信息
        isOpenBtn:true,//是否显示项目右侧功能按钮
        ismyCourse:true,//是否显示项目右侧销量
        isHotpost:true,//是否显示项目右侧热门帖子
        isCelebrity:true,//是否显示项目右侧名人榜
        isMyconpoment:true,//项目右侧我的模块是否展示
        isComponents:true,//是否显示项目右侧模块
        isBannar:false,//是否显示首页top广告位
        isadvert:false
    },
    mutations: {
        setactive(state,data){
            state.luyouactive = data
        },
        setrightismy(state,data){
            state.rightismy = data
        },
        setloginstatus(state,data){
            state.loginStatus = data
        },
        setloginuserinfo(state,data){
            state.loginuserinfo = data
        },
        setUserInfo(state,data){
            state.userinfo = data
        },
        sethotPost(state,data){
            state.hotpostList = data
        },
        setCelebrity(state,data){
            state.CelebrityList = data
        },
        setComponent(state,data){
            state.isComponents = data
        },
        setBannar(state,data){
            state.isBannar = data
        },
        setisCompons(state,data){
            console.log(data);
            // val1,val2,val3,val4,val5,val6,val7
            state.isComponents = data[0]
            if(!data[0]) return
            state.isvideolist = data[1]
            if(state.loginStatus){
                state.isUserinfo = data[2]
            }else{
                state.isUserinfo = false
            }
            
            state.isOpenBtn = data[3]
            state.isMyconpoment = data[4]
            state.ismyCourse = data[5]
            state.isHotpost = data[6]
            state.isCelebrity = data[7]
            state.isadvert = data[8]?true:false
        },
        setuse(state,data){
            if(state.loginStatus){
                state.isUserinfo = data
            }else{
                state.isUserinfo = false
            }
        }
    },
    actions: {
        async getloginuserinfo(cxt){
            const data = await api.login.getloginuserinfo()
            console.log(data);
            if(data.code==0){
                cxt.commit('setloginstatus',true)
                cxt.commit('setloginuserinfo',data.data)
                cxt.commit('setuse',true)
                
            }
        },
        async getuserinfo(cxt,id){
            
            const data = await api.login.getIdUserInfo(id)
            if(data.code==0){
                console.log(data);
                cxt.commit('setUserInfo',data.data)
                return data.data
            }
            
            
        },
    }
}
