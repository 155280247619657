import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/homepage'),
    children: [
      {
        path: '/',
        name: '外贸江湖',
        component: () => import('@/views/foreigntrade/index'),
      },
      {
        path: '/cardef',
        name: '帖子详情',
        component: () => import('@/views/foreigntrade/indexdef'),
      },
      {
        path: '/publisharticle',
        name: '发布帖子',
        component: () => import('@/views/foreigntrade/publisharticle'),
      },
      {
        path: '/celebrity',
        name: '名人堂',
        component: () => import('@/views/celebrity/index'),
      },
      {
        path: '/videoplay',
        name: '课程视频',
        component: () => import('@/views/celebrity/videoplay'),
      },
      {
        path: '/pushvideo',
        name: '发布课程',
        component: () => import('@/views/celebrity/pushvideo'),
      },
      {
        path: '/robda',
        name: '抢答',
        component: () => import('@/views/robda/index'),
      },
      {
        path: '/robdadeta',
        name: '抢答',
        component: () => import('@/views/robda/robdadeta'),
      },
      {
        path: '/efficient',
        name: '高效',
        component: () => import('@/views/efficient/index'),
      },
      {
        path: '/integral',
        name: '积分商城',
        component: () => import('@/views/integral/index'),
      },
      {
        path: '/listudy',
        name: '利他学习',
        component: () => import('@/views/listudy/index'),
      },
      {
        path: '/listudydef',
        name: '利他学习详情',
        component: () => import('@/views/listudy/indexdef'),
      },
      {
        path: '/myorder',
        name: '订单数据',
        component: () => import('@/views/mypage/myorder'),
      },
      {
        path: '/mypage',
        name: '我的页面',
        component: () => import('@/views/mypage/index'),
      },
      {
        path: '/myvideo',
        name: '我的课程',
        component: () => import('@/views/mypage/myvideo'),
      },
      {
        path: '/myinfoset',
        name: '编辑个人资料',
        component: () => import('@/views/mypage/myinfoset'),
      },
      {
        path: '/myquestions',
        name: '我的问答',
        component: () => import('@/views/mypage/mywenda'),
      },
      {
        path: '/mycollect',
        name: '我的收藏',
        component: () => import('@/views/mypage/mycollect'),
      },
      {
        path: '/mypost',
        name: '我的发布',
        component: () => import('@/views/mypage/mypost'),
      }
    ]
  }
]



const router = new VueRouter({
  mode: 'hash',
  base: './',
  routes
})

router.beforeEach((to, from, next) => {
  console.log('====================================');
  console.log(to);
  console.log('====================================');
  switch (to.fullPath) {
    case '/':
      store.commit('homePage/setactive','1')
      break;
      case '/celebrity':
        store.commit('homePage/setactive','2')
      break;
      case '/robda':
        store.commit('homePage/setactive','3')
      break;
      case '/listudy':
        store.commit('homePage/setactive','4')
      break;
      case '/efficient':
        store.commit('homePage/setactive','5')
      break;
      case '/integral':
        store.commit('homePage/setactive','6')
      break;
    default:
      store.commit('homePage/setactive','100')
      break;
  }
  // 在每次路由跳转前滚动到顶部
  
  window.scrollTo(0, 0);
  // 或者使用 document.documentElement.scrollTop 或 document.body.scrollTop
  // document.documentElement.scrollTop = 0;
  // document.body.scrollTop = 0;

  next();
});

export default router
